import React from 'react';
import { Link } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useTranslation } from "react-i18next";
import SelectableLanguageDropDown from "./SelectableLanguageDropDown";

export default function NavMenu(props) {

    const { t } = useTranslation();
    const [currentUser, setCurrentUser] = React.useState(null);
    const { onButtonClick } = props;

    React.useEffect(() => {

        let user = AuthService.getCurrentUser();

        setCurrentUser(user);


    }, []);

    const logOut = (e) => {
        AuthService.logout();
        e.preventDefault();
    }

    const logIn = (e) => {
        if (process.env.NODE_ENV === "production")
            window.location.replace('https://auth.agrifoodatsolutions.com?service=AGRF_LIC_WEBAPP&solution=AGRF_LIC');
        else
            window.location.href = '/login';
        e.preventDefault();
    }

    return (
        <div>
            <nav className="navbar navbar-expand navbar-dark" style={{ backgroundColor: "#0069B4" }}>
                <div className="container-fluid">
                    <div className="navbar-brand" onClick={onButtonClick} href="/#"><img src="https://cdn.agrifoodatsolutions.com/img/licensing_logo_40x40.png" height="40" width="40" alt="Caedis Licensing" /></div>

                    {currentUser ? (
                        <div className="navbar-nav ml-auto">
                            <div className="nav-item" style={{ padding: "-5px" }}>
                                <SelectableLanguageDropDown />
                            </div>
                            <div className="hda-button-userOpts-actions">
                                <Link to={"/profile"} className="nav-link acs-button-userOpts-Opts">{t("Global_Label_Text_Profile")}</Link>
                            </div>
                            <div className="hda-button-userOpts-actions">
                                <a href="/login" className="nav-link acs-button-userOpts-Opts" onClick={(e) => logOut(e)}>{t("Global_Label_Text_Logout")}</a>
                            </div>
                        </div>
                    ) : (
                        <div className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to={"/regsitro"} onClick={(e) => logIn(e)} className="nav-link">{t("Global_Label_Text_Login")}</Link>
                            </li>
                        </div>
                    )}
                </div>
            </nav>
        </div>
    );

}
